// Core
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { AddButton } from 'components';
import LinkItem from '../LinkItem/LinkItem';
import MuiMultipleCheckedSmartlinkOutlets from 'material-design/components/MuiMultipleCheckedSmartlinkOutlets/MuiMultipleCheckedSmartlinkOutlets';

// Styles
import styles from './Outlets.module.css';

const Outlets = ({
	iframeLoading,
	availableOutlets,
	links,
	onFocusOutlet,
	onBlurOutlet,
	updateOutletLink,
	deleteOutletLink,
	dragItem,
	dragOverItem,
	handleSort,
	handleCheckedOutlets,
	addCustomOutlet,
	errors,
}) => {
	const { lang } = useContext(LangContext);

	const [isShowOutletSelect, setIsShowOutletSelect] = useState(false);

	const getLabelFromUrl = (url) => {
		// Find the matching outlet by checking if its URL is included in the provided URL
		const matchedOutlet = availableOutlets.find(
			(outlet) => outlet.key && url.includes(outlet.key)
		);

		// Return the appropriate label or fallback
		return matchedOutlet
			? lang === 'en'
				? matchedOutlet.title_en
				: matchedOutlet.title_ru
			: lang === 'ru'
			? 'ссылка'
			: 'link';
	};

	return (
		<div className={styles.container}>
			<h4 className={styles.title}>
				<FormattedMessage id="rod.smartlink_form.outlets" />
			</h4>
			<div className={styles.linksContainer}>
				{links?.map((item, index) => (
					<div
						key={item.id}
						draggable={!iframeLoading}
						onDragStart={
							!iframeLoading ? () => (dragItem.current = index) : () => {}
						}
						onDragEnter={
							!iframeLoading ? () => (dragOverItem.current = index) : () => {}
						}
						onDragEnd={!iframeLoading ? handleSort : () => {}}
						onDragOver={(e) => e.preventDefault()}
					>
						<LinkItem
							outlets
							iframeLoading={iframeLoading}
							label={getLabelFromUrl(item.url)}
							link={item.url}
							item={item}
							onFocusOutlet={onFocusOutlet}
							onBlurOutlet={onBlurOutlet}
							updateOutletLink={updateOutletLink}
							deleteOutletLink={deleteOutletLink}
							errors={errors}
							field="links"
							outletItemIndex={index}
						/>
					</div>
				))}
				{links?.length < 40 && (
					<AddButton
						plusWithoutBorder
						customStyles={{
							justifyContent: 'left',
							background: 'transparent',
							border: 'none',
							font: 'var(--gilroy-Medium-18-24)',
						}}
						title="rod.smartlink_form.outlets.add_button"
						onClick={() => setIsShowOutletSelect(true)}
					/>
				)}
				{isShowOutletSelect && availableOutlets?.length > 0 && (
					<div className={styles.outletsSelect}>
						<MuiMultipleCheckedSmartlinkOutlets
							dataOutlets={links}
							availableOutlets={availableOutlets}
							handleCheckedOutlets={handleCheckedOutlets}
							setIsShow={setIsShowOutletSelect}
							addCustomOutlet={addCustomOutlet}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Outlets;
