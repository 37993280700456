export const isValidHttpUrl = (url, id) => {
	if (id && typeof id === 'string') return true;

	try {
		const parsedUrl = new URL(url);
		return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
	} catch (e) {
		return false;
	}
};
