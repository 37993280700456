// Core
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

// UI
import { CopyButton, CustomButton, DeleteButton } from 'components';
import empty from 'images/emptyCoverAlbum.png';

// Styles
import styles from './TableElement.module.css';

const TableElement = ({
	item,
	index,
	page,
	onEditSmartlink,
	onPreviewSmartlink,
	onDeleteSmartlink,
	disable,
}) => {
	const history = useHistory();
	const releaseUrl = `/repertoire/releases/${item.release_h11}/info`;

	const handleClick = (e, id) => {
		// Middle click or Ctrl/Cmd+click – let the browser open in new tab
		if (
			e.metaKey || // Cmd (Mac)
			e.ctrlKey || // Ctrl (Windows/Linux)
			e.button === 1 // Middle click
		) {
			return;
		}

		// Left click – use SPA navigation
		e.preventDefault();
		history.push(`/repertoire/releases/${id}/info`);
	};

	return (
		<li className={styles.tableElement}>
			<div className={styles.infoWrapper}>
				<div className={styles.place}>{index + 1 + (page - 1) * 10}</div>
				{item.release_image && (
					<Link
						className={styles.cover}
						to={releaseUrl}
						onClick={(e) => handleClick(e, item.release_h11)}
					>
						<img src={item.release_image ?? empty} alt="" />
					</Link>
				)}
				<div>
					<Link
						className={`${styles.text} ${styles.title}`}
						to={releaseUrl}
						title={item.release_name}
						onClick={(e) => handleClick(e, item.release_h11)}
					>
						{item.release_name}
					</Link>
					<p className={styles.subtitle} title={item.artist_name}>
						{item.artist_name}
					</p>
				</div>
			</div>
			<span className={styles.text}>{item.date}</span>
			<div className={styles.actions}>
				<CopyButton data={item.url} dark />
				<CustomButton
					disable={disable}
					mode="edit"
					onHandle={() => onEditSmartlink(item)}
				/>
				<CustomButton
					disable={disable}
					mode="preview"
					onHandle={() => onPreviewSmartlink(item)}
				/>
				<DeleteButton
					disable={disable}
					onDeleteHandler={() => onDeleteSmartlink(item)}
				/>
			</div>
		</li>
	);
};

export default TableElement;
