// Core
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';
import lscache from 'lscache';
import { v4 as uuidv4 } from 'uuid';

// Services
import { accounts } from 'services';

// UI
import { Modal, Shade } from './Posed';
import BaseModal from '../BaseModal';
import { CopyButton, DeleteButton, Loading } from 'components';
import { Container } from 'components/Grid';
import General from './SmartlinkForm/General/General';
import Outlets from './SmartlinkForm/Outlets/Outlets';
// import Socials from './SmartlinkForm/Socials/Socials';
import Themes from './SmartlinkForm/Themes/Themes';
import ModalControls from './ModalControls/ModalControls';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './SmartlinkModal.module.css';

const CACHE_KEY = 'smartlink_outlets_cache';
const CACHE_TTL = 10; // Cache time in minutes

const SmartlinkModal = ({
	title,
	subtitle,
	onClose,
	accountId,
	releaseId,
	smartlinkData,
	setSmartlinkData,
	generateSmartlinkForRelease,
	getSmartlinkById,
	updateSmartlinkById,
	getSmartlinkOutlets,
	repertoireRelease,
	onDeleteSmartlink,
}) => {
	const [loading, setLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [iframeKey, setIframeKey] = useState(0);
	const [iframeLoading, setIframeLoading] = useState(false);
	const [smartlinkIdInitial, setSmartlinkIdInitial] = useState(null);
	const [fieldOriginalValues, setFieldOriginalValues] = useState({});
	const [originalLinkUrls, setOriginalLinkUrls] = useState({});
	const [smartlinkFormData, setSmartlinkFormData] = useState(null);
	const [pendingPayload, setPendingPayload] = useState(null);
	const [availableOutlets, setAvailableOutlets] = useState([]);
	const [errors, setErrors] = useState({});

	const dragItem = useRef(null);
	const dragOverItem = useRef(null);

	useEffect(() => {
		setIsVisible(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const getOutlets = () => {
		const cachedOutlets = lscache.get(CACHE_KEY);

		if (cachedOutlets) {
			setAvailableOutlets(cachedOutlets);
			return;
		}

		getSmartlinkOutlets().then((res) => {
			setAvailableOutlets(res.data.data);
			lscache.set(CACHE_KEY, res.data.data, CACHE_TTL);
		});
	};

	// Debounced API call (only fires after user stops typing)
	const debouncedUpdate = useCallback(
		debounce((payload) => {
			setIframeLoading(true);
			setErrors({});
			let smartlinkDataId = smartlinkData?.id ?? smartlinkIdInitial;
			updateSmartlinkById(accountId, releaseId, smartlinkDataId, payload)
				.then((res) => {
					setSmartlinkFormData(res.data.data);
					setIframeKey((prev) => prev + 1); // Update iframe **only after API success**
				})
				.catch((e) => {
					setErrors(e.response?.data?.errors);
					console.error('Error updating Smartlink:', e);
				})
				.finally(() => setIframeLoading(false));
		}, 200), // delay to prevent excessive requests
		[accountId, releaseId, smartlinkData.id, smartlinkIdInitial]
	);

	// Handles input changes but doesn't trigger API immediately
	const changeField = (field) => (e) => {
		let updatedData = { ...smartlinkFormData };

		switch (field) {
			case 'theme':
				updatedData[field] = e.id;
				setPendingPayload(updatedData);
				break;
			default:
				updatedData[field] = e.target.value;
				break;
		}

		setSmartlinkFormData(updatedData);
	};

	const onFocusField = (field) => () => {
		setFieldOriginalValues((prev) => ({
			...prev,
			[field]: smartlinkFormData[field],
		}));
	};

	const onBlurField = (field) => (e) => {
		const newValue = e.target.value;
		const oldValue = fieldOriginalValues[field];

		if (oldValue !== newValue) {
			const updatedData = {
				...smartlinkFormData,
				[field]: newValue,
			};

			setPendingPayload(updatedData);
		}
	};

	const deleteOutletLink = (id) => {
		setSmartlinkFormData((prevState) => {
			const updatedData = {
				...prevState,
				links: prevState.links.filter((link) => link.id !== id),
			};
			if (typeof id === 'string') {
				return updatedData;
			} else {
				setPendingPayload(updatedData);
				return updatedData;
			}
		});
	};

	const onFocusOutlet = (id, currentUrl) => () => {
		setOriginalLinkUrls((prev) => ({
			...prev,
			[id]: currentUrl,
		}));
	};

	const onBlurOutlet = (id, currentUrl) => () => {
		const originalUrl = originalLinkUrls[id];

		if (originalUrl !== currentUrl) {
			// Only update if it actually changed
			setSmartlinkFormData((prevState) => {
				const updatedData = {
					...prevState,
					links: prevState.links.map((link) =>
						link.id === id ? { ...link, url: currentUrl } : link
					),
				};
				setPendingPayload(updatedData);
				return updatedData;
			});
		}
	};

	const updateOutletLink = (id, newUrl) => {
		setSmartlinkFormData((prevState) => {
			const updatedData = {
				...prevState,
				links: prevState.links.map((link) =>
					link.id === id ? { ...link, url: newUrl } : link
				),
			};
			// setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const handleSort = () => {
		let items = [...smartlinkFormData.links];
		const draggedItemContent = items.splice(dragItem.current, 1)[0];
		items.splice(dragOverItem.current, 0, draggedItemContent);
		dragItem.current = null;
		dragOverItem.current = null;

		// Update order field based on new index
		const updatedItems = items.map((item, index) => ({
			...item,
			order: index,
		}));

		setSmartlinkFormData((prevState) => {
			const updatedData = { ...prevState, links: updatedItems };
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const handleCheckedOutlets = (selectedOutlets) => {
		if (!smartlinkFormData) return;

		const existingLinks = smartlinkFormData.links || [];
		const upc = smartlinkFormData.upc;

		// Filter out already added outlets
		const newLinksToAdd = selectedOutlets.filter((outlet) => {
			return !existingLinks.some((link) =>
				outlet.key ? link.id === outlet.key : link.url.includes(outlet.key)
			);
		});

		// Map them into the correct link format
		const mappedLinks = newLinksToAdd.map((outlet, index) => ({
			id: outlet.key,
			url: outlet.url,
			upc: upc,
			order: existingLinks.length + index + 1,
		}));

		// Update state
		setSmartlinkFormData((prev) => ({
			...prev,
			links: [...existingLinks, ...mappedLinks],
		}));
	};

	const addCustomOutlet = () => {
		setErrors({});
		if (!smartlinkFormData || smartlinkFormData.links.length === 40) return;

		setSmartlinkFormData((prev) => ({
			...prev,
			links: [
				...prev.links,
				{ id: uuidv4(), url: '', order: prev.links.length },
			],
		}));
	};

	// const deleteSocialLink = (key) => {
	// 	setSmartlinkFormData((prevState) => {
	// 		const updatedData = { ...prevState };
	// 		updatedData[key] = '';
	// 		setPendingPayload(updatedData);
	// 		return updatedData;
	// 	});
	// };

	// const updateSocialLink = (key, newUrl) => {
	// 	setSmartlinkFormData((prevState) => {
	// 		const sanitizedUrl = newUrl.trim() === '' ? null : newUrl;
	// 		const updatedData = { ...prevState, [key]: sanitizedUrl };

	// 		// Only trigger setPendingPayload if the value actually changed
	// 		if (sanitizedUrl !== prevState[key]) {
	// 			setPendingPayload(updatedData);
	// 		}

	// 		return updatedData;
	// 	});
	// };

	// const handleSwitcher = (value) => {
	// 	setErrors({});
	// 	setSmartlinkFormData((prevState) => {
	// 		const updatedData = {
	// 			...prevState,
	// 			social_links_enabled: value,
	// 		};

	// 		// If enabling, make sure all social links are at least empty strings
	// 		if (value) {
	// 			[
	// 				'artist_official',
	// 				'artist_twitter',
	// 				'artist_facebook',
	// 				'artist_instagram',
	// 				'artist_youtube',
	// 				'artist_vk',
	// 			].forEach((key) => {
	// 				if (updatedData[key] === null) {
	// 					updatedData[key] = ''; // Ensure all fields are displayed
	// 				}
	// 			});
	// 		}

	// 		// Check if the switcher was changed from ON to OFF
	// 		const switchedOff = prevState.social_links_enabled;

	// 		// Only update pending payload if user actually modifies socials
	// 		const hasChanges = Object.keys(updatedData).some(
	// 			(key) =>
	// 				key.startsWith('artist_') &&
	// 				updatedData[key] !== prevState[key] &&
	// 				updatedData[key] !== ''
	// 		);

	// 		if (switchedOff || hasChanges) {
	// 			setPendingPayload(updatedData);
	// 		}
	// 		return updatedData;
	// 	});
	// };

	// Triggers API call only when user stops typing
	useEffect(() => {
		if (pendingPayload) {
			debouncedUpdate({
				...pendingPayload,
				social_links_enabled: pendingPayload.social_links_enabled ?? false,
			});
		}
	}, [pendingPayload, debouncedUpdate]);

	useEffect(() => {
		setLoading(true);
		getOutlets();
		if (typeof smartlinkData === 'boolean') {
			try {
				generateSmartlinkForRelease(accountId, releaseId).then((res) => {
					if (res.data.data.id) {
						setSmartlinkIdInitial(res.data.data.id);
						getSmartlinkById(accountId, releaseId, res.data.data.id)
							.then((result) => {
								setSmartlinkFormData({
									...result.data.data,
									theme: result.theme ?? 'light',
									social_links_enabled:
										result.data.data.social_links_enabled ?? false,
								});
								if (repertoireRelease) {
									accounts
										.getReleaseInfoForBrowsing(accountId, releaseId)
										.then((info) => {
											info = info.data.data;
											if (info.hasOwnProperty('smart_link')) {
												setSmartlinkData(
													info.smart_link ? info.smart_link : true
												);
											}
										})
										.catch((error) => {
											console.error('Error', error);
										});
								}
							})
							.catch()
							.finally(() => setLoading(false));
					}
				});
			} catch (error) {
				console.error('Error while generate smartlink');
			}
		} else {
			getSmartlinkById(accountId, releaseId, smartlinkData.id)
				.then((res) =>
					setSmartlinkFormData({
						...res.data.data,
						theme: res.theme ?? 'light',
						social_links_enabled: res.data.data.social_links_enabled ?? false,
					})
				)
				.catch()
				.finally(() => setLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BaseModal className={styles.modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.overlay} />,
					<div key="iframe-container" className={styles.iframeContainer}>
						{iframeLoading ? (
							<Loading />
						) : (
							smartlinkFormData?.url && (
								<iframe
									key={iframeKey} // Ensures iframe reloads with fresh data
									src={smartlinkFormData.url}
									className={styles.smartlinkIframe}
									frameBorder="0"
									allowFullScreen
									title={`Smartlink preview for ${
										smartlinkFormData.release_name || 'release'
									}`}
								></iframe>
							)
						)}
					</div>,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container className={styles.container} fluid>
							<div>
								<div
									md={12 - 4}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								<div className={styles.bodyContainer}>
									{loading ? (
										<Loading />
									) : (
										<>
											<div className={styles.bodyWrapper}>
												<div className={styles.bodyTopWrapper}>
													<div></div>
													<CloseIcon
														onClick={(e) => {
															willUnmount(e);
														}}
														style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className={styles.bodyHeader}>
													{title && (
														<div className={styles.bodyTitle}>
															<FormattedMessage id={title} />
															<div className={styles.titleButtons}>
																<CopyButton data={smartlinkFormData.url} dark />
																<DeleteButton
																	onDeleteHandler={() =>
																		onDeleteSmartlink(smartlinkData)
																	}
																/>
															</div>
														</div>
													)}
													{subtitle && (
														<div className={styles.bodySubtitle}>
															<FormattedMessage id={subtitle} />
														</div>
													)}
												</div>
												<General
													iframeLoading={iframeLoading}
													data={smartlinkFormData}
													changeField={changeField}
													onFocusField={onFocusField}
													onBlurField={onBlurField}
													errors={errors}
												/>
												<Outlets
													iframeLoading={iframeLoading}
													availableOutlets={availableOutlets}
													links={smartlinkFormData.links}
													handleCheckedOutlets={handleCheckedOutlets}
													dragItem={dragItem}
													dragOverItem={dragOverItem}
													handleSort={handleSort}
													onFocusOutlet={onFocusOutlet}
													onBlurOutlet={onBlurOutlet}
													updateOutletLink={updateOutletLink}
													deleteOutletLink={deleteOutletLink}
													addCustomOutlet={addCustomOutlet}
													errors={errors}
												/>
												{/* 												TODO в разработке */}
												{/* <Socials
													data={smartlinkFormData}
													handleSwitcher={handleSwitcher}
													updateSocialLink={updateSocialLink}
													deleteSocialLink={deleteSocialLink}
													errors={errors}
												/> */}
												<Themes
													iframeLoading={iframeLoading}
													data={smartlinkFormData}
													changeField={changeField}
												/>
											</div>
											<ModalControls
												willUnmount={willUnmount}
												url={smartlinkFormData.url}
											/>
										</>
									)}
								</div>
							</div>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default SmartlinkModal;
