// Core
import { FormattedMessage } from 'react-intl';

// Constants
import { smartlinkThemes } from 'constants/smartlinkThemes';

// UI
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from './Themes.module.css';

const Themes = ({ iframeLoading, data, changeField }) => {
	return (
		<div className={styles.container}>
			<h4 className={styles.title}>
				<FormattedMessage id="rod.smartlink_form.theme" />
			</h4>
			<FormInput
				type="muiInputSelect"
				name="theme"
				onChange={changeField}
				errors={{}}
				data={data}
				label={<FormattedMessage id="rod.smartlink_form.theme.label" />}
				items={smartlinkThemes}
				disabled={iframeLoading}
			/>
		</div>
	);
};

export default Themes;
