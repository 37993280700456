// Core
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Types
import ModalTypes from 'constants/modalTypes';

// UI
import { InputWithClear } from 'material-design/components';
import SmartlinksList from './SmartlinksList/SmartlinksList';

// Styles
import styles from './SmartlinksPage.module.css';

const SmartlinksPage = () => {
	const { accountId } = useContext(AuthContext);
	const {
		getSmartlinks,
		deleteSmartlink,
		getSmartlinkById,
		updateSmartlinkById,
		getSmartlinkOutlets,
		generateSmartlinkForRelease,
	} = useContext(RootContext);
	const {
		showTitle,
		hideTitle,
		showBackNavi,
		hideBackNavi,
		showModal,
	} = useContext(UIContext);

	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [search, setSearch] = useState('');
	const [smartlinks, setSmartlinks] = useState([]);
	const [disable, setDisable] = useState(false);

	const getSmartlinksData = (searchValue = search, pageNumber = page) => {
		setLoading(true);
		getSmartlinks(accountId, pageNumber, searchValue)
			.then((res) => {
				setSmartlinks(res.data.data);
				setTotal(res.data.total);
			})
			.catch((err) => console.error('Error while getting smartlinks', err))
			.finally(() => setLoading(false));
	};

	const onChange = (value) => {
		if (value.length >= 3) {
			setSearch(value);
			setPage(1);
		} else if (!value) {
			setSearch('');
			setPage(1);
		}
	};

	// Debounce search input
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSearchSmartlinks = useCallback(debounce(onChange, 300), []);

	const onEditSmartlink = (data) => {
		showModal(
			{
				title: 'rod.release.smart_link.edit.modal',
				accountId,
				releaseId: data.release_h11,
				generateSmartlinkForRelease,
				getSmartlinkById,
				updateSmartlinkById,
				getSmartlinkOutlets,
				smartlinkData: data,
				onDeleteSmartlink,
			},
			ModalTypes.SMARTLINK
		)();
	};

	const onPreviewSmartlink = (data) => {
		if (data?.url) {
			window.open(data.url, '_blank');
		}
	};

	const onDeleteHandler = (data) => {
		setDisable(true);
		deleteSmartlink(accountId, data.release_h11, data.id)
			.then(() => {
				const filteredSmartlinks = smartlinks.filter((el) => el.id !== data.id);
				setSmartlinks(filteredSmartlinks);
			})
			.catch((err) => console.error('Error while delete smartlink, err'))
			.finally(() => setDisable(false));
	};

	const onDeleteSmartlink = (data) => {
		showModal(
			{
				title: <FormattedMessage id="rod.compositions.delete_confirm" />,
				text: <FormattedMessage id="rod.marketing.smartlink.delete.text" />,
				onAction: () => {
					onDeleteHandler(data);
				},
				confirmBtnTxt: <FormattedMessage id="rod.modal.yes_delete" />,
				declineBtnTxt: <FormattedMessage id="rod.modal.cancel" />,
			},
			ModalTypes.FULL_MODAL
		)();
	};

	useEffect(() => {
		showBackNavi();
		showTitle('rod.navbar.smartlinks');

		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSmartlinksData(search, page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, page]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<h3 className={styles.title}>
					<FormattedMessage id="rod.marketing.smartlink.title" />
				</h3>
				<div className={styles.searchInput}>
					<FormattedHTMLMessage id="rod.marketing.smartlink.search.placeholder">
						{(placeholder) => (
							<InputWithClear
								handleOnChange={onSearchSmartlinks}
								placeholder={placeholder}
							/>
						)}
					</FormattedHTMLMessage>
				</div>
			</div>
			<SmartlinksList
				loading={loading}
				page={page}
				setPage={setPage}
				total={total}
				smartlinks={smartlinks}
				onEditSmartlink={onEditSmartlink}
				onPreviewSmartlink={onPreviewSmartlink}
				onDeleteSmartlink={onDeleteSmartlink}
				disable={disable}
			/>
		</div>
	);
};

export default SmartlinksPage;
