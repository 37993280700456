// Core
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { AddButton } from 'components';
import { Label } from 'components/Labels';
import MenuItem from '@mui/material/MenuItem';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';

// Hooks
import { useClickOutside } from 'hooks';

// Styles
import s from './MuiMultipleCheckedSmartlinkOutlets.module.css';

const MuiMultipleCheckedSmartlinkOutlets = (props) => {
	const { lang } = useContext(LangContext);
	const containerRef = useRef(null);
	useClickOutside(containerRef, () => props.setIsShow(false));

	const [outlets, setOutlets] = useState([]);

	useEffect(() => {
		const selectedOutlets = props.availableOutlets.map((outlet) => {
			const isSelected = props.dataOutlets.some((link) =>
				outlet.key
					? link.url.includes(outlet.key)
					: link.url.includes(outlet.url)
			);
			return {
				...outlet,
				checked: isSelected,
			};
		});

		setOutlets(selectedOutlets);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.availableOutlets]);

	const isOutletSelected = (outlet) => {
		return props.dataOutlets.some((link) =>
			outlet.key ? link.url.includes(outlet.key) : link.url.includes(outlet.url)
		);
	};

	const handleChecked = (outlet) => {
		if (outlet.checked) {
			return;
		}

		let updatedOutlets = outlets.map((item) =>
			item.key === outlet.key
				? { ...item, checked: !item.checked }
				: item
		);

		setOutlets(updatedOutlets);
		const selectedArray = updatedOutlets.filter(
			(item) => item.checked && !isOutletSelected(item)
		);
		props.handleCheckedOutlets(selectedArray);
	};

	return (
		<div className={s.wrapper} ref={containerRef}>
			{outlets.map((outlet, index) => (
				<MenuItem
					key={outlet.key}
					className={s.menuItem}
					onClick={(e) => {
						if (e.target.tagName !== 'svg') {
							handleChecked(outlet);
						}
					}}
					value={outlet.raw_title}
				>
					<CheckboxBlack
						checked={outlet.checked}
						onChange={() => handleChecked(outlet)}
					/>
					<Label>{lang === 'ru' ? outlet.title_ru : outlet.title_en}</Label>
				</MenuItem>
			))}
			<AddButton
				customStyles={{
					justifyContent: 'left',
					height: '48px',
					padding: '8px 12px',
					font: 'var(--gilroy-Medium-18-24)',
					border: 'none',
					borderTop: '2px solid var(--color-grey100)',
				}}
				title="rod.smartlink_form.outlets.add_new_button"
				onClick={() => props.addCustomOutlet()}
			/>
		</div>
	);
};

export default compose(withRoot, withAuth)(MuiMultipleCheckedSmartlinkOutlets);
