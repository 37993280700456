// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import add_plus from 'images/addPlus.svg';
import add_box_line from 'images/add_box_line.svg';

// Styles
import styles from './AddButton.module.css';

const AddButton = ({
	title,
	disabled,
	onClick,
	plusWithoutBorder,
	customStyles,
}) => {
	return (
		<button
			className={styles.addButton}
			style={customStyles}
			disabled={disabled}
			onClick={onClick}
		>
			<img src={plusWithoutBorder ? add_plus : add_box_line} alt="plus" />
			<span>
				<FormattedMessage id={title} />
			</span>
		</button>
	);
};

export default AddButton;
