// Utils
import { isValidHttpUrl } from 'utils';

// UI
import { InputWithFloatingLabel } from 'components/Inputs/InputWithFloatingLabel';
import { DeleteButton } from 'components/Buttons';

// Icons
import sort from 'images/sort_dnd_2.svg';

// Styles
import styles from './LinkItem.module.css';

const LinkItem = ({
	field,
	outlets,
	label,
	link,
	item,
	onFocusOutlet,
	onBlurOutlet,
	updateOutletLink,
	deleteOutletLink,
	updateSocialLink,
	deleteSocialLink,
	errors,
	iframeLoading,
	outletItemIndex,
}) => {
	return (
		<div className={styles.container}>
			{outlets && (
				<img className={styles.sort} src={sort} alt="dnd" draggable />
			)}
			<InputWithFloatingLabel
				customStyles={outlets ? { width: '370px' } : { width: '395px' }}
				placeholder={label}
				value={link}
				onFocus={onFocusOutlet ? onFocusOutlet(item.id, link) : () => {}}
				onBlur={onBlurOutlet ? onBlurOutlet(item.id, link) : () => {}}
				onChange={
					outlets
						? (value) => updateOutletLink(item.id, value)
						: (value) => updateSocialLink(value)
				}
				dataField={field}
				errors={errors}
				outletItemIndex={outletItemIndex}
				disabled={iframeLoading || (outlets && !isValidHttpUrl(link, item.id))}
			/>
			<DeleteButton
				disable={iframeLoading}
				onDeleteHandler={
					outlets ? () => deleteOutletLink(item.id) : deleteSocialLink
				}
			/>
		</div>
	);
};

export default LinkItem;
